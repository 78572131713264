import '../App.css';
import Hero from '../Components/Hero';

function Home() {
    return (
        <div>
            <Hero />    
        </div>
    );
}

export default Home;