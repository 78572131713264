import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Plus, Minus } from 'lucide-react';
import '../App.css';

const API_URL = '/.netlify/functions';

const ProductDetails = () => {
  const { id, appearanceId, ideaId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [isAddingToBasket, setIsAddingToBasket] = useState(false);
  const [selectedSize, setSelectedSize] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();

  const handleAppearanceChange = (newAppearanceId) => {
    navigate(`/product/${id}/${newAppearanceId}/${ideaId}`, { replace: true });
  };

  const incrementQuantity = () => setQuantity(prev => prev + 1);
  const decrementQuantity = () => setQuantity(prev => prev > 1 ? prev - 1 : 1);

  const nextImage = () => {
    setSelectedImage((prev) => (prev + 1) % product.images.length);
  };

  const prevImage = () => {
    setSelectedImage((prev) => (prev - 1 + product.images.length) % product.images.length);
  };

  const Dialog = ({ isOpen, onClose, onGoToBasket }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg p-6 max-w-sm w-full m-4">
          <h3 className="text-xl font-bold text-gray-900 mb-4">
            Item Added to Basket
          </h3>
          <p className="text-gray-600 mb-6">
            Your item has been successfully added to the basket.
          </p>
          <div className="flex gap-4 justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
            >
              Continue Shopping
            </button>
            <button
              onClick={onGoToBasket}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Go to Basket
            </button>
          </div>
        </div>
      </div>
    );
  };

  const addToBasket = async () => {
    setIsAddingToBasket(true);
    try {
      const basketId = sessionStorage.getItem('basketId');
   
      if (basketId !== "undefined" && basketId !== null) {
        console.log('Updating existing basket...');
        const getResponse = await fetch(`${API_URL}/getBasket?basketId=${basketId}`);
        const currentBasket = await getResponse.json();
   
        // Check if item with same appearance and size exists
        const existingItemIndex = currentBasket.basketItems.findIndex(item => {
          const itemAppearance = item.element.properties.find(p => p.key === "appearance")?.value;
          const itemSize = item.element.properties.find(p => p.key === "size")?.value;
          return itemAppearance === appearanceId && itemSize === selectedSize.id;
        });
   
        let updatedBasket;
        if (existingItemIndex !== -1) {
          // Update quantity of existing item
          updatedBasket = {
            ...currentBasket,
            basketItems: currentBasket.basketItems.map((item, index) => 
              index === existingItemIndex 
                ? { ...item, quantity: item.quantity + quantity }
                : item
            )
          };
        } else {
          // Add new item
          updatedBasket = {
            ...currentBasket,
            basketItems: [...currentBasket.basketItems, {
              quantity: quantity,
              element: {
                id: product.sellableId,
                type: "sprd:sellable",
                properties: [
                  { key: "size", value: selectedSize.id },
                  { key: "appearance", value: appearanceId }
                ],
                shop: { id: "100887470" }
              }
            }]
          };
        }
   
        await fetch(`${API_URL}/updateBasket?basketId=${basketId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedBasket)
        });
        setShowDialog(true);
      } else {
        // Create new basket code remains the same
        console.log('Creating new basket...');
        const payload = {
          basketItems: [{
            quantity: quantity,
            element: {
              id: product.sellableId,
              type: "sprd:sellable",
              properties: [
                { key: "size", value: selectedSize.id },
                { key: "appearance", value: appearanceId }
              ],
              shop: { id: "100887470" }
            }
          }]
        };
   
        console.log('Sending payload:', payload);
   
        const response = await fetch(`${API_URL}/createBasket`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
   
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Failed to create basket: ${errorData.error || response.status}`);
        }
   
        const basketData = await response.json();
        console.log('Basket created:', basketData);
        sessionStorage.setItem('basketId', basketData.id);
        setShowDialog(true);
      }
    } catch (error) {
      console.error('Error handling basket:', error);
    } finally {
      setIsAddingToBasket(false);
    }
   };

  const addToBasketSection = (
    <div className="mt-8">
      <div className="flex items-center gap-4 mb-4">
        <span className="text-white">Quantity:</span>
        <div className="flex items-center bg-white/10 rounded-lg">
          <button 
            onClick={decrementQuantity}
            className="p-2 hover:bg-white/5 rounded-l-lg"
          >
            <Minus className="text-white" size={20} />
          </button>
          <span className="px-4 text-white">{quantity}</span>
          <button 
            onClick={incrementQuantity}
            className="p-2 hover:bg-white/5 rounded-r-lg"
          >
            <Plus className="text-white" size={20} />
          </button>
        </div>
      </div>
      
      <button
        onClick={addToBasket}
        disabled={isAddingToBasket || !selectedSize}
        className="w-full py-3 px-6 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
      >
        {isAddingToBasket ? 'Adding to Basket...' : 'Add to Basket'}
      </button>
    </div>
  );

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${API_URL}/product?id=${id}&appearanceId=${appearanceId}&ideaId=${ideaId}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setProduct(data);
        setSelectedImage(0);
      } catch (err) {
        console.error('Fetch error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [id, appearanceId, ideaId]);

  if (loading) return (
    <div className="w-full h-screen sellables_container flex justify-center items-center">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
    </div>
  );

  if (error) return (
    <div className="w-full h-screen sellables_container flex justify-center items-center text-white">
      <p className="text-xl">Error: {error}</p>
    </div>
  );

  return (
    <div className='min-h-screen'>
      <div className='w-full pt-20 sellables_container'>
        <div className='max-w-[1100px] mx-auto p-4'>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="relative">
              <div className="relative group">
                <img
                  src={product.images[selectedImage].url}
                  alt={product.name}
                  className="w-full rounded-lg shadow-xl mb-4"
                />
                <button 
                  onClick={prevImage}
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black/50 p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <ChevronLeft className="text-white" size={24} />
                </button>
                <button 
                  onClick={nextImage}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black/50 p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <ChevronRight className="text-white" size={24} />
                </button>
              </div>
              <div className="flex gap-2 overflow-x-auto pb-2">
                {product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.url}
                    alt={`View ${index + 1}`}
                    onClick={() => setSelectedImage(index)}
                    className={`cursor-pointer rounded-lg h-20 w-20 object-cover flex-shrink-0 
                      ${selectedImage === index ? 'border-2 border-blue-500' : 'border-2 border-transparent'}`}
                  />
                ))}
              </div>
            </div>
            
            <div>
              <h1 className="text-3xl font-bold mb-4 text-white">{product.name}</h1>
              <p className="text-2xl mb-4 text-white">{product.price.amount} DKK</p>
              <p className="text-gray-300 mb-6">{product.description}</p>
              <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2 text-white">Colors</h2>
                <div className="flex gap-2 flex-wrap">
                  {product.appearances?.map((appearance) => (
                    <button
                      key={appearance.id}
                      onClick={() => handleAppearanceChange(appearance.id)}
                      className={`w-8 h-8 rounded-full border-2 transition-all ${
                        appearance.id === appearanceId 
                          ? 'border-blue-500 scale-110' 
                          : 'border-transparent hover:scale-105'
                      }`}
                      style={{
                        backgroundColor: appearance.colors[0].value,
                        boxShadow: appearance.id === appearanceId ? '0 0 0 2px rgba(59, 130, 246, 0.5)' : 'none'
                      }}
                      title={appearance.name}
                    />
                  ))}
                </div>
                <h2 className="text-xl font-semibold mb-2 text-white">Sizes</h2>
                {product?.sizes ? (
                  <div className="flex gap-2">
                    {product.sizes.map((size) => (
                      <button
                        key={size.id}
                        onClick={() => setSelectedSize(size)}
                        className={`px-4 py-2 rounded-lg text-white 
                          ${selectedSize?.id === size.id 
                            ? 'bg-blue-600' 
                            : 'bg-white/10 hover:bg-white/20'
                          }`}
                      >
                        {size.name}
                      </button>
                    ))}
                  </div>
                  ) : (
                    <p className="text-white">{product.json}</p>
                  )}
            </div>
              {addToBasketSection}
            </div>
          </div>
        </div>
      </div>
      <Dialog 
        isOpen={showDialog} 
        onClose={() => setShowDialog(false)}
        onGoToBasket={() => navigate('/basket')}
      />
    </div>
  );
};

export default ProductDetails;