import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Minus, Trash2 } from 'lucide-react';

const API_URL = '/.netlify/functions';

const Basket = () => {
  const navigate = useNavigate();
  const [basket, setBasket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);

  const fetchBasket = useCallback(async () => {
    const basketId = sessionStorage.getItem('basketId');
    if (!basketId) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/getBasket?basketId=${basketId}`);
      const data = await response.json();
      setBasket(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBasket();
  }, [fetchBasket]);

  const updateQuantity = async (itemIndex, newQuantity) => {
    setUpdating(true);
    try {
      // Get current basket state
      const basketId = sessionStorage.getItem('basketId');
      const getResponse = await fetch(`${API_URL}/getBasket?basketId=${basketId}`);
      const currentBasket = await getResponse.json();
   
      // Update quantity for the specific item
      const updatedBasket = {
        ...currentBasket,
        basketItems: currentBasket.basketItems.map((item, index) => 
          index === itemIndex ? { ...item, quantity: newQuantity } : item
        )
      };
   
      // Send update request
      const response = await fetch(`${API_URL}/updateBasket?basketId=${basketId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedBasket)
      });
   
      // Update UI with new basket data
      const data = await response.json();
      setBasket(data);
      
    } catch (err) {
      console.error('Error updating quantity:', err);
      setError(err.message);
      console.log('Error fetching basket:', error);
    } finally {
      setUpdating(false);
    }
   };

   const removeItem = async (itemIndex) => {
    setUpdating(true);
    try {
      // Get current basket state
      const basketId = sessionStorage.getItem('basketId');
      const getResponse = await fetch(`${API_URL}/getBasket?basketId=${basketId}`);
      const currentBasket = await getResponse.json();
   
      // Remove item from basket
      const updatedBasket = {
        ...currentBasket,
        basketItems: currentBasket.basketItems.filter((_, index) => index !== itemIndex)
      };
   
      // Update basket with removed item
      const updateResponse = await fetch(`${API_URL}/updateBasket?basketId=${basketId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedBasket)
      });
   
      const data = await updateResponse.json();
      setBasket(data);
   
    } catch (err) {
      console.error('Error removing item:', err);
      setError(err.message);
    } finally {
      setUpdating(false);
    }
   };

  // const goToCheckout = () => {
  //   if (basket?.links?.checkoutUrl) {
  //     window.location.href = basket.links.checkoutUrl;
  //   }
  // };

  if (loading) return (
    <div className="w-full min-h-screen pt-20 flex justify-center items-center">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
    </div>
  );

  // Add this check for empty or non-existent basket
  if (!basket || !basket.basketItems || basket.basketItems.length === 0) return (
    <div className='min-h-screen'>
        <div className="w-full min-h-screen pt-20 sellables_container flex flex-col items-center">
        <h1 className="text-2xl font-bold text-white mb-4">Your basket is empty</h1>
        <button
            onClick={() => navigate('/shop')}
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
            Continue Shopping
        </button>
        </div>
    </div>
  );

  return (
    <div className='min-h-screen'>
        <div className="w-full min-h-screen pt-20 sellables_container">
            <div className="max-w-[1100px] mx-auto p-4">
            <h1 className="text-3xl font-bold text-white mb-8">Your Basket</h1>
            
            <div className="space-y-4">
                {basket.basketItems.map((basketItem, index) => (
                <div key={basketItem.id} className="bg-white/10 rounded-lg p-4">
                {/* Mobile layout: stack vertically */}
                <div className="flex flex-col md:flex-row md:items-center gap-4">
                  {/* Image: larger on mobile */}
                  <div className="w-full md:w-24 h-40 md:h-24 bg-white/5 rounded-lg flex items-center justify-center">
                    <img
                      src={`https://image.spreadshirtmedia.net/image-server/v1/products/${basketItem.element.properties.find(p => p.key === 'product')?.value}`}
                      alt={basketItem.description}
                      className="w-32 h-32 md:w-20 md:h-20 object-contain"
                    />
                  </div>
                  
                  {/* Product details */}
                  <div className="flex-grow">
                    <h3 className="text-xl md:text-lg font-semibold text-white">
                      {basketItem.description}
                    </h3>
                    <p className="text-gray-300">
                      Size: {basketItem.element.properties.find(p => p.key === 'sizeLabel')?.value || 'N/A'}
                    </p>
                    <p className="text-gray-300">
                      Color: {basketItem.element.properties.find(p => p.key === 'appearanceLabel')?.value || 'N/A'}
                    </p>
                    <p className="text-white text-lg mt-2">
                      {basketItem.price.display} DKK
                    </p>
                  </div>
              
                  {/* Quantity controls: full width on mobile */}
                  <div className="flex items-center justify-between md:justify-end gap-2 mt-4 md:mt-0">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => updateQuantity(index, Math.max(1, basketItem.quantity - 1))}
                        disabled={updating}
                        className="p-3 md:p-2 bg-white/5 rounded hover:bg-white/10 disabled:opacity-50"
                      >
                        <Minus className="text-white" size={20} />
                      </button>
                      <span className="text-white w-8 text-center">{basketItem.quantity}</span>
                      <button
                        onClick={() => updateQuantity(index, basketItem.quantity + 1)}
                        disabled={updating}
                        className="p-3 md:p-2 bg-white/5 rounded hover:bg-white/10 disabled:opacity-50"
                      >
                        <Plus className="text-white" size={20} />
                      </button>
                    </div>
                    <button
                      onClick={() => removeItem(index)}
                      disabled={updating}
                      className="p-3 md:p-2 bg-white/5 rounded hover:bg-white/10 disabled:opacity-50"
                    >
                      <Trash2 className="text-white" size={20} />
                    </button>
                  </div>
                </div>
              </div>
                ))}
            </div>

            {basket.basketItems.length > 0 && (
                <div className="mt-8 border-t border-white/10 pt-4">
                  <div className="flex flex-col gap-2 text-white mb-4">
                      <div className="flex justify-between">
                      <span>Subtotal:</span>
                      <span>{basket.priceItems.display} DKK</span>
                      </div>
                      <div className="flex justify-between">
                      <span>Shipping:</span>
                      <span>{basket.shipping.price.display} DKK</span>
                      </div>
                      <div className="flex justify-between text-xl font-bold">
                      <span>Total:</span>
                      <span>{basket.priceTotal.display} DKK</span>
                      </div>
                  </div>
                  <div className='flex flex-row gap-4'>
                    <button
                        onClick={() => navigate(`/shop`)}
                        disabled={updating}
                        className="w-full py-3 px-6 bg-neutral-600 text-white rounded-lg hover:bg-neutral-500 disabled:opacity-50"
                    >
                        Continue Shopping
                    </button>
                    <button
                        onClick={() => window.location.href = basket.links.find(link => link.type === 'shopCheckout')?.href}
                        disabled={updating}
                        className="w-full py-3 px-6 bg-blue-600 text-white rounded-lg hover:bg-blue-500 disabled:opacity-50"
                    >
                        Proceed to Checkout
                    </button>
                  </div>
                </div>
            )}
            </div>
        </div>
    </div>
  );
};

export default Basket;