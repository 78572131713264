import React from 'react';
import '../App.css';
import HanifiUnderTree from '../images/Hanifi_Under_Tree.jpg';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Stack, Button } from '@mui/material';

const Hero = () => {
  return (
    <div className='w-full h-screen sellables_container'>
      <img
        className='top-0 left-0 w-full h-screen object-cover'
        src={HanifiUnderTree}
        alt='Hanifi Under Tree'
      />
      <div className='bg-black/30 absolute top-0 left-0 w-full h-screen' />
      <div className='absolute top-0 w-full h-full flex flex-col justify-center text-white'>
        <div className='md:left-[10%] max-w-[1100px] m-auto absolute p-4'>
          <p>What are you waiting for?</p>
          <h1 className='font-bold text-5xl md:text-7xl drop-shadow-2xl'>
            Happy Outdoors
          </h1>
          <p className='max-w-[600px] drop-shadow-2xl py-2 text-xl'>
            Dedicated woodsman looking for unique experiences in nature.
            Beautiful and simple, nothing more.
          </p>
          <Stack direction='row' spacing={2}>
            <Button variant="outlined"
                    color='inherit'
                    sx={{
                        '&:hover': {
                          backgroundColor: '#FDFDFD',
                          color: '#050606',
                          transition: 'all 0.3s ease-out',
                        },
                      }}
                    endIcon={<InstagramIcon />} 
                    href='https://www.instagram.com/happy.outdoors_dk/'>
                Instagram
            </Button>
            <Button variant="outlined"
                    color='inherit'
                    sx={{
                        '&:hover': {
                          backgroundColor: '#FDFDFD',
                          color: '#050606',
                          transition: 'all 0.3s ease-out',
                        },
                      }} 
                    endIcon={<ShoppingCartIcon />} 
                    href='/shop'>
                Shop
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Hero;