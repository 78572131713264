import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const API_URL = '/.netlify/functions';

const Sellables = () => {
  const navigate = useNavigate();
  const [sellables, setSellables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSellables = async () => {
      try {
        const response = await fetch(`${API_URL}/sellables`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setSellables(data.sellables || []);
      } catch (err) {
        console.error('Fetch error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSellables();
  }, []);

  if (loading) return (
    <div className="w-full h-screen sellables_container flex justify-center items-center">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
    </div>
  );

  if (error) return (
    <div className="w-full h-screen sellables_container flex justify-center items-center text-white">
      <p className="text-xl">Error: {error}</p>
    </div>
  );

  return (
    <div className='w-full pt-20 sellables_container'>
      <div className='max-w-[1100px] mx-auto p-4'>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
          {sellables.map((item) => (
            <img
                src={item.previewImage.url}
                key={item.sellableId}
                alt={item.name}
                onClick={() => navigate(`/product/${item.sellableId}/${item.defaultAppearanceId}/${item.ideaId}`)}
                className="w-full object-contain rounded-lg mb-4 drop-shadow-2xl item_img"
            />
          ))}
        </div>
      </div>
    </div>
    );
};

export default Sellables;