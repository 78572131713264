import '../App.css';
import Sellables from '../Components/Sellables';

function Shop() {
    return (
        <div className='min-h-screen'>
            <Sellables />
        </div>
    );
}

export default Shop;